import { Image, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import CameraIcon from "@mui/icons-material/CameraEnhance";
import { Box, Paper, Skeleton, useTheme } from "@mui/material";
import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import { TimeRange } from "@src/lib/interface/src/lib/shift";
import { useEffect } from "react";

import { useQRSignatureDataUrl } from "./useQRSignatureDataUrl";

type QRSignatureFlowProps = {
  shiftId: string;
  clockInOut: TimeRange;
  lunchInOut?: TimeRange;
  breakAuthorizationReason?: string;
  onSuccess: () => void;
};

export function QRSignatureFlow(props: QRSignatureFlowProps) {
  const { onSuccess, ...otherProps } = props;
  const { qrCodeDataUrl, isQrCodeDataUrlLoading } = useQRSignatureDataUrl(otherProps);
  const { isSuccess: isGetShiftSuccess, data: shiftData } = useGetShift(props.shiftId, {
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
  });
  const shift = isGetShiftSuccess ? shiftData.response : undefined;
  const hasFiles = isDefined(shift?.timecard?.files) && shift?.timecard?.files.length > 0;
  const theme = useTheme();

  useEffect(() => {
    if (hasFiles) {
      onSuccess();
    }
  }, [hasFiles, onSuccess]);

  if (hasFiles) {
    return null;
  }

  return (
    <Box>
      <Text
        style={{
          marginBottom: "24px",
          textAlign: "left",
          fontSize: "16px",
          lineHeight: "1.0",
          fontWeight: 400,
        }}
      >
        Please ask the <strong>charge nurse or nursing supervisor</strong> you worked with to scan
        the following QR code and provide a signature.
      </Text>

      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "24px",
          position: "relative",
        }}
      >
        <Box
          style={{
            position: "relative",
            padding: "8px",
            width: "320px",
            height: "320px",
          }}
        >
          {["top-left", "top-right", "bottom-left", "bottom-right"].map((corner) => (
            <Box
              key={corner}
              style={{
                position: "absolute",
                width: "20px",
                height: "20px",
                border: "2px solid #1976d2",
                ...(corner.includes("top") ? { top: 0 } : { bottom: 0 }),
                ...(corner.includes("left") ? { left: 0 } : { right: 0 }),
                borderBottom: corner.includes("top") ? "none" : "2px solid #1976d2",
                borderTop: corner.includes("bottom") ? "none" : "2px solid #1976d2",
                borderRight: corner.includes("left") ? "none" : "2px solid #1976d2",
                borderLeft: corner.includes("right") ? "none" : "2px solid #1976d2",
              }}
            />
          ))}

          {isQrCodeDataUrlLoading && <Skeleton variant="rectangular" width="100%" height="100%" />}
          {qrCodeDataUrl && (
            <Image
              src={qrCodeDataUrl}
              alt="QR Code"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}
          {!isQrCodeDataUrlLoading && !qrCodeDataUrl && (
            <Text>Error generating QR. Please close this popup and try again.</Text>
          )}
        </Box>
      </Box>

      <Paper
        style={{
          backgroundColor: "#f0f7ff",
          padding: "12px",
          boxShadow: "none",
          borderRadius: "8px",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "8px",
            gap: "8px",
          }}
        >
          <CameraIcon sx={{ color: theme.palette.primary.main, fontSize: "20px" }} />
          <Text style={{ color: theme.palette.primary.main }} bold>
            How to scan:
          </Text>
        </Box>
        <Box style={{ paddingLeft: "8px" }}>
          {[
            "Open your phone's camera app",
            "Point it at the QR code above",
            "Tap the notification that appears",
          ].map((instruction, index) => (
            <Text
              key={instruction}
              style={{
                color: theme.palette.primary.main,
                marginBottom: index < 2 ? "4px" : 0,
                fontSize: "14px",
              }}
            >
              {`${index + 1}. ${instruction}`}
            </Text>
          ))}
        </Box>
      </Paper>
    </Box>
  );
}
